import React from "react";
import {AnimatePresence} from "framer-motion";
import {Header} from "./Header";
import {Item} from "./Item";
import {List} from "./List";
import {Route, Routes, useParams} from "react-router-dom";

function Store() {
    let {id} = useParams();
    console.log(`id: ${id}`)
    const imageHasLoaded = true;

    return (
        <>
            <List selectedId={id}/>
            <AnimatePresence>
                {id && imageHasLoaded && <Item id={id} key="item"/>}
            </AnimatePresence>
        </>
    );
}

export default function App() {
    return (
        <div className="container">
            <Header/>
            <Routes>
                <Route eaxct path="/" element={<Store/>}/>
                <Route path="/:id" element={<Store/>}/>
            </Routes>
        </div>
    );
}
