import React from "react";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {items} from "./data";

export function Item({id}) {
    const {subtitle, title,link, description} = items.find((item) => item.id === id);

    return (
        <>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.2, delay: 0.1}}
                style={{pointerEvents: "auto"}}
                className="overlay"
            >
                <Link to="/"/>

            </motion.div>
            <div className="card-content-container open">

                <motion.div className="card-content" layoutId={`card-container-${id}`}>

                    <motion.div
                        className="card-image-container"
                        layoutId={`card-image-container-${id}`}
                    >
                        <img className="card-image" src={`images/${id}.jpg`} alt=""/>
                    </motion.div>
                    <motion.div
                        className="title-container"
                        layoutId={`title-container-${id}`}
                    >
                        <span className="category">{subtitle}</span>
                        <h2>{title}</h2>
                        <Link to={link} target="_blank">


                         <span className="card-content-visit">
                    Visit ↗

                    </span>
                        </Link>
                    </motion.div>
                    <motion.div className="content-container" animate>
                        {description}
                    </motion.div>
                </motion.div>
            </div>
        </>
    );
}
