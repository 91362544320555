export const items = [
    // Photo by Helena Lopes on Unsplash
    {
        id: "community",
        title: "Community website",
        pointOfInterest: 80,
        backgroundColor: "#814A0E",
        link:"https://community-for-afg.netlify.app/",
        description: "A website for the community to share events and programs",
    },
    // Photo by Raghav Bhasin on Unsplash
    {
        id: "lab",
        title: "Synthesis workshop",
        pointOfInterest: 260,
        backgroundColor: "#5DBCD2",
        link:"https://synthesis-workshop.com/",
        description:"Synthesis workshop is a website for chemistry and science lovers!"
    },
    // Photo from Google
    {
        id: "f",
        subtitle: "Around the U.S.",
        description: "A friendly space were you can share your photos from around the U.S.",
        pointOfInterest: 200,
        backgroundColor: "#8F986D",
        link:"https://rezamohdev.github.io/se_project_aroundtheus/",
    },
    // Photo by Gabor Koszegi on Unsplash
    {
        id: "travel",
        subtitle: "Travellian",
        pointOfInterest: 150,
        backgroundColor: "#FA6779",
        link:"https://main--amazing-sherbet-d588cc.netlify.app/",
        description:"A website for sharing your favorite places"
    },
    // Photo by  Angela Bailey on Unsplash
    {
        id: "clothes",
        title: "What to wear",
        pointOfInterest: 120,
        backgroundColor: "#959684",
        link:"https://github.com/rezamohdev/se_project_react",
        description:"A website that people can share their favorite clothes"
    },
    // Photo by Siora Photography on Unsplash
    // {
    //     id: "h",
    //     subtitle: "They're all the same",
    //     title: "100 Cupcake Apps for the Cupcake Connoisseur",
    //     pointOfInterest: 60,
    //     backgroundColor: "#282F49",
    //     link:"",
    //     description:""
    // },
    // Photo by Yerlin Matu on Unsplash
    // {
    //     id: "e",
    //     subtitle: "Cats",
    //     title: "Yes, They Are Sociopaths",
    //     pointOfInterest: 200,
    //     backgroundColor: "#AC7441",
    //     link:"",
    //     description:""
    // },
    // Photo by Ali Abdul Rahman on Unsplash
    // {
    //     id: "b",
    //     subtitle: "Holidays",
    //     title: "Seriously the Only Escape is the Stratosphere",
    //     pointOfInterest: 260,
    //     backgroundColor: "#CC555B",
    //     link:"",
    //     description:""
    // }
];

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
