import * as React from "react";
import Avatar from "./avatar.jpeg";
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fab} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";


library.add(fas, far, fab);
export const Header = () => (<header>
    <div>
        <span className="date">Reza Mohammadi</span>
        <h3>Web developer</h3>
    </div>
    <div className="header-right">

           <ul className="header-icons">
              <Link to="https://www.linkedin.com/in/its-reza/" target="_blank"  className="header-icon"><FontAwesomeIcon icon={["fab","linkedin"]}/></Link>
              <Link to="https://github.com/iits-reza" target="_blank" className="header-icon"><FontAwesomeIcon icon={["fab","github"]}/></Link>
              <Link to="mailto:rezamoh847@gmail.com" className="header-icon"><FontAwesomeIcon icon={["far","envelope"]} /></Link>
           </ul>

        <div className="avatar">
            <img src={Avatar} alt="avatar"/>
        </div>
    </div>
</header>);
